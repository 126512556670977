const FIXED_LENGTH = 4;

export function formatLatLng(coordinates: [number, number]): string {
  const [lat, lng] = coordinates;

  const latSuffix = lat >= 0 ? 'N' : 'S';
  const lngSuffix = lng >= 0 ? 'E' : 'W';
  const degreeSymbol = '\u00B0';

  const latFormat = `${Math.abs(lat).toFixed(
    FIXED_LENGTH,
  )}${degreeSymbol} ${latSuffix}`;
  const lngFormat = `${Math.abs(lng).toFixed(
    FIXED_LENGTH,
  )}${degreeSymbol} ${lngSuffix}`;

  return `${latFormat}, ${lngFormat}`;
}
