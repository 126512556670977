import { styled, Theme } from '@mui/material';
import { StyledComponent } from '@mui/styled-engine';
import { MUIStyledCommonProps } from '@mui/system';
import {
  DateRangePickerDay as MuiDateRangePickerDay,
  DateRangePickerDayProps,
  dateRangePickerDayClasses,
} from '@mui/x-date-pickers-pro/DateRangePickerDay';

type StyledDateRangePickerDay = StyledComponent<
  DateRangePickerDayProps<Date> &
    React.RefAttributes<HTMLButtonElement> &
    MUIStyledCommonProps<Theme>,
  any,
  any
>;

const DateRangePickerDay = styled(MuiDateRangePickerDay)(
  ({
    theme,
    isHighlighting,
    isStartOfHighlighting,
    isEndOfHighlighting,
    selected,
  }) => ({
    ...(isHighlighting && {
      backgroundColor: 'rgba(145, 203, 236, 0.2)',
      borderTop: `solid 1px ${theme.palette.success.main}`,
      borderBottom: `solid 1px ${theme.palette.success.main}`,
      '& button': {
        color: '#000000',
      },
      '&:first-of-type': {
        borderLeft: `solid 1px ${theme.palette.success.main}`,
      },
      '&:last-of-type': {
        borderRight: `solid 1px ${theme.palette.success.main}`,
      },
    }),
    ...(isStartOfHighlighting && {
      backgroundColor: 'rgba(145, 203, 236, 0.2)',
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    }),
    ...(isEndOfHighlighting && {
      backgroundColor: 'rgba(145, 203, 236, 0.2)',
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
    }),
    ...(selected && {
      backgroundColor: 'rgba(145, 203, 236, 0.2)',
    }),
    [`&.${dateRangePickerDayClasses.root} button`]: {
      '&.Mui-selected': {
        backgroundColor: theme.palette.success.main,
        border: 0,
      },
    },
  }),
) as StyledDateRangePickerDay;

export const renderWeekPickerDay = (
  _: unknown,
  dateRangePickerDayProps: DateRangePickerDayProps<Date>,
) => {
  return <DateRangePickerDay {...dateRangePickerDayProps} />;
};
