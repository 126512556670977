import ErrorIcon from '@mui/icons-material/Error';
import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Link,
  Typography,
} from '@mui/material';

import { config } from '../../config/config';

export function ErrorModal({ children, open, onClose }: DialogProps) {
  return (
    <Dialog
      PaperProps={{
        sx: {
          padding: 3,
        },
      }}
      onClose={onClose}
      open={open}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <ErrorIcon color="error" sx={{ height: '100%', mr: 1 }} />
          <Typography fontSize="1.75rem" fontWeight={600} variant="h3">
            Server Error
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography mb={2} variant="body1">
          Opps, something went wrong. Submitting of the request failed. Please
          try again later.
        </Typography>
        {children && (
          <Typography mb={2} variant="body1">
            Reason: {children}
          </Typography>
        )}
        <Typography fontWeight={700} variant="body1">
          Still happening?
        </Typography>
        <Typography variant="body1">
          Please contact us at{' '}
          <Link href={`mailto:${config.contact.email}`}>
            {config.contact.email}
          </Link>{' '}
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
