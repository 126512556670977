import { Fragment } from 'react';
import { Control, FieldArrayWithId } from 'react-hook-form';

import { SchemaType } from '../../pages/CheckerPage/schema';

import { InputRow } from './InputRow';

interface IProps {
  control: Control<SchemaType>;
  disabled?: boolean;
  fields: FieldArrayWithId<SchemaType>[];
  maxDate?: Date;
  onRemoveRow?: (index: number) => void;
}

export function InputRows({
  control,
  disabled,
  fields,
  maxDate,
  onRemoveRow,
}: IProps) {
  return (
    <Fragment>
      {fields.map((field, index) => (
        <InputRow
          control={control}
          disabled={disabled}
          index={index}
          key={field.id}
          maxDate={maxDate}
          onRemoveRow={onRemoveRow}
        />
      ))}
    </Fragment>
  );
}
