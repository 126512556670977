import {
  AuthorizeDocument,
  AuthorizeMutationVariables,
  ForgotPasswordDocument,
  ForgotPasswordMutationVariables,
  LoginDocument,
  LoginMutationVariables,
  ResetPasswordDocument,
  ResetPasswordMutationVariables,
} from '../../../graphql/generated';

export const authorizeEndpoint = {
  query: (variables: AuthorizeMutationVariables) => ({
    document: AuthorizeDocument,
    variables,
  }),
};

export const forgotPasswordEndpoint = {
  query: (variables: ForgotPasswordMutationVariables) => ({
    document: ForgotPasswordDocument,
    variables,
  }),
};

export const loginEndpoint = {
  query: (variables: LoginMutationVariables) => ({
    document: LoginDocument,
    variables,
  }),
};

export const resetPasswordEndpoint = {
  query: (variables: ResetPasswordMutationVariables) => ({
    document: ResetPasswordDocument,
    variables,
  }),
};
