import { RouteObject } from 'react-router-dom';

import { CheckerPageContainer } from '../../pages/CheckerPage';
import { ForgotPasswordPageContainer } from '../../pages/ForgotPassword';
import { LoginPageContainer } from '../../pages/LoginPage';
import { NotFoundPage } from '../../pages/NotFoundPage';
import { ResetPasswordPageContainer } from '../../pages/ResetPasswordPage';

import { ProtectedRouteCheck } from './ProtectedRouteCheck';
import { UnprotectedRouteCheck } from './UnprotectedRouteCheck';

export const routes: RouteObject[] = [
  {
    // Unprotected Routes
    element: <UnprotectedRouteCheck />,
    children: [
      {
        path: '/',
        element: <LoginPageContainer />,
      },
      {
        path: '/forgot-password',
        element: <ForgotPasswordPageContainer />,
      },
      {
        path: '/reset-password',
        element: <ResetPasswordPageContainer />,
      },
    ],
  },
  {
    // Protected Routes
    element: <ProtectedRouteCheck />,
    children: [
      {
        path: '/checker',
        element: <CheckerPageContainer />,
      },
    ],
  },
  {
    // Not Found
    path: '*',
    element: <NotFoundPage />,
  },
];
