import { Box, Stack } from '@mui/material';

import { InputFormContainer } from '../../components/InputForm/InputForm.container';
import { Results } from '../../components/Results';
import { ISummary } from '../../components/Results/interfaces';

import { SchemaType } from './schema';

interface IProps {
  data?: any;
  isLoading?: boolean;
  onSubmit: (value: SchemaType) => void;
  rainThreshold?: number;
  summary?: ISummary;
}

export function CheckerPage({
  data,
  isLoading,
  onSubmit,
  rainThreshold,
  summary,
}: IProps) {
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      fontSize="0.85rem"
      height="100%"
      justifyContent="center"
      marginTop={10}
      minHeight="30vh"
    >
      <Stack spacing={2} maxWidth="1000px">
        <InputFormContainer onSubmit={onSubmit} isDisabled={isLoading} />
        {(data || isLoading) && (
          <Results
            data={data}
            summary={summary}
            isLoading={isLoading}
            rainThreshold={rainThreshold}
          />
        )}
      </Stack>
    </Box>
  );
}
