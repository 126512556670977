export enum LocalStorageKeys {
  USER_TOKEN = 'userToken',
}

export function getLoginToken() {
  return localStorage.getItem(LocalStorageKeys.USER_TOKEN) || '';
}

export function setLoginToken(token: string) {
  localStorage.setItem(LocalStorageKeys.USER_TOKEN, token);
}
