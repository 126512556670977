import { TagDescription } from '@reduxjs/toolkit/src/query/endpointDefinitions';
import { GraphQLResponse } from 'graphql-request/dist/types';

import { CacheTag } from '../store/api/CacheTags';

export interface IMetaExtras {
  response: GraphQLResponse;
}

export function getTagsFromResults<T extends string>(
  array: Array<{ _id?: string | null } | null>,
  key: T,
) {
  return array
    .map((item) =>
      item
        ? {
            type: key,
            id: item._id || null,
          }
        : undefined,
    )
    .filter(Boolean) as readonly TagDescription<T>[];
}

export function getGraphqlErrorsFromMeta(meta: IMetaExtras) {
  return meta?.response?.errors;
}

export function getGenericProvidedTages<ResultType>(
  key: keyof ResultType,
  tag: CacheTag,
) {
  return (result: any | undefined): readonly TagDescription<CacheTag>[] => {
    const tags = getTagsFromResults(result?.[key] || [], tag);

    return [...tags, { type: tag, id: 'LIST' }];
  };
}
