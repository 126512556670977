import { Paper } from '@mui/material';
import { useState } from 'react';

import { HorizontalRuler } from '../HorizontalRuler';
import { ERA5Modal } from '../Modals';

import { ResultHeader } from './ResultHeader';
import { ResultTable } from './ResultTable';
import { ResultTableLoader } from './ResultTableLoader';
import { ISummary } from './interfaces';

interface IProps {
  data?: any;
  isLoading?: boolean;
  summary?: ISummary;
  rainThreshold?: number;
}

export function Results({ data, isLoading, summary, rainThreshold }: IProps) {
  const [era5ModalOpen, setIsEra5ModalOpen] = useState(false);

  return (
    <Paper sx={{ padding: 4 }}>
      <ResultHeader
        qualified={summary?.qualified}
        thresholdDays={summary?.daysAboveThreshold}
        thresholdPercentage={summary?.percentageAboveThreshold}
        threshold={rainThreshold}
        isLoading={isLoading}
        onClick={() => setIsEra5ModalOpen(true)}
      />
      <HorizontalRuler />
      {isLoading && <ResultTableLoader />}
      {!isLoading && <ResultTable rows={data} />}
      <ERA5Modal
        open={era5ModalOpen}
        onClose={() => setIsEra5ModalOpen(false)}
      />
    </Paper>
  );
}
