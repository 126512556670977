import { createApi } from '@reduxjs/toolkit/query/react';

import {
  AuthorizeMutation,
  AuthorizeMutationVariables,
  CurrentUserQuery,
  CurrentUserQueryVariables,
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables,
  GetRainfallDataByLocationsQuery,
  GetRainfallDataByLocationsQueryVariables,
  LoginMutation,
  LoginMutationVariables,
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
} from '../../graphql/generated';

import { CacheTag } from './CacheTags';
import {
  authorizeEndpoint,
  currentUserEndpoint,
  forgotPasswordEndpoint,
  getRainfallDataByLocationsEndpoint,
  loginEndpoint,
  resetPasswordEndpoint,
} from './endpoints';
import { graphqlRequestQuery } from './graphqlRequestQuery';

export const api = createApi({
  reducerPath: 'api',
  tagTypes: [...Object.values(CacheTag)],
  baseQuery: graphqlRequestQuery,
  endpoints: (builder) => ({
    authorize: builder.mutation<AuthorizeMutation, AuthorizeMutationVariables>({
      ...authorizeEndpoint,
    }),
    currentUser: builder.query<CurrentUserQuery, CurrentUserQueryVariables>({
      ...currentUserEndpoint,
    }),
    forgotPassword: builder.mutation<
      ForgotPasswordMutation,
      ForgotPasswordMutationVariables
    >({
      ...forgotPasswordEndpoint,
    }),
    getRainfallDataByLocationsEndpoint: builder.mutation<
      GetRainfallDataByLocationsQuery,
      GetRainfallDataByLocationsQueryVariables
    >({
      ...getRainfallDataByLocationsEndpoint,
    }),
    login: builder.mutation<LoginMutation, LoginMutationVariables>({
      ...loginEndpoint,
    }),
    resetPassword: builder.mutation<
      ResetPasswordMutation,
      ResetPasswordMutationVariables
    >({
      ...resetPasswordEndpoint,
    }),
  }),
});
