import {
  CurrentUserDocument,
  CurrentUserQueryVariables,
} from '../../../graphql/generated';

export const currentUserEndpoint = {
  query: (variables: CurrentUserQueryVariables) => ({
    document: CurrentUserDocument,
    variables,
  }),
};
