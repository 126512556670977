import { Box, Skeleton, Typography } from '@mui/material';

interface IProps {
  title?: string;
  value?: string;
  isLoading?: boolean;
}

export function InfoBox({ isLoading, title, value }: IProps) {
  return (
    <Box mr={7}>
      <Typography color="grey.80" fontSize="1.25rem" fontWeight={600} mb={0.5}>
        {title}
      </Typography>
      {!isLoading && (
        <Typography fontSize="1.75rem" fontWeight={700}>
          {value}
        </Typography>
      )}
      {isLoading && (
        <Skeleton
          height={40}
          sx={{ bgcolor: 'grey.40', borderRadius: '6px' }}
          variant="rectangular"
          width={60}
        />
      )}
    </Box>
  );
}
