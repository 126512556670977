import { Skeleton } from '@mui/material';
import { Fragment } from 'react';

export function ResultTableLoader() {
  return (
    <Fragment>
      {Array(10)
        .fill(null)
        .map((_: unknown, index) => (
          <Skeleton key={index} height={40} sx={{ borderRadius: '6px' }} />
        ))}
    </Fragment>
  );
}
