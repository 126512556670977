import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

interface IProps {
  email?: string;
  open?: boolean;
  onClose: () => void;
}

export function PasswordResetSuccessModal({
  email,
  open = false,
  onClose,
}: IProps) {
  return (
    <Dialog
      PaperProps={{
        sx: {
          padding: 3,
        },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <CheckIcon color="success" sx={{ height: '100%', mr: 1 }} />
          <Typography fontSize="1.75rem" fontWeight={600} variant="h3">
            Password updated!
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography maxWidth={400}>
          Password for your account has been successfully updated.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
