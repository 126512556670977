import SendIcon from '@mui/icons-material/Send';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

interface IProps {
  email?: string;
  open?: boolean;
  onClose: () => void;
}

export function ForgotPasswordSentModal({
  email,
  open = false,
  onClose,
}: IProps) {
  return (
    <Dialog
      PaperProps={{
        sx: {
          padding: 3,
        },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <SendIcon color="success" sx={{ height: '100%', mr: 1 }} />
          <Typography fontSize="1.75rem" fontWeight={600} variant="h3">
            Reset email sent
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography maxWidth={400}>
          Please check{' '}
          <Box component="span" color="grey.80">
            {email}
          </Box>{' '}
          for a password reset link.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
