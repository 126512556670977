import { Box } from '@mui/material';
import { useCallback } from 'react';

import { getTranslatedErrorMessage } from '../../helpers';
import { useAuth } from '../../hooks';
import { useLoginMutation } from '../../store/api';

import { LoginPage } from './LoginPage';
import { ILoginPageSchema } from './LoginPage.schema';

export function LoginPageContainer() {
  // logging in setup
  const { login } = useAuth();
  const [mutate, { error }] = useLoginMutation();

  const onSubmit = useCallback(
    async (data: ILoginPageSchema) => {
      const results = await mutate({
        email: data.email,
        password: data.password,
      });

      if ('data' in results && results.data?.login) {
        const isConfirmed = results.data?.login?.user?.confirmedAt;

        if (isConfirmed) {
          login(results.data.login);
        } else {
          console.error('User is not confirmed');
        }
      }
    },
    [login, mutate],
  );

  const errorMessage = getTranslatedErrorMessage(error);

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
      minHeight="80vh"
    >
      <LoginPage onSubmit={onSubmit} errorMessage={errorMessage} />
    </Box>
  );
}
