import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { Metadata } from './interfaces';

const BASE_URL = 'https://api.prod.arbol.cloud/rest/singlife/';
const ERA5_METADATA_PATH = 'era5-precip-hourly';

export const dclimateApi = createApi({
  reducerPath: 'dclimateApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: (builder) => ({
    metaERA5PrecipHourly: builder.query<Metadata, undefined>({
      query: () => ERA5_METADATA_PATH,
    }),
  }),
});

export const {
  useLazyMetaERA5PrecipHourlyQuery,
  useMetaERA5PrecipHourlyQuery,
} = dclimateApi;
