import { addDays, isBefore, subDays } from 'date-fns';
import { Fragment, useCallback, useState } from 'react';

import { ErrorModal } from '../../components/Modals';
import {
  GetRainfallDataByLocationsQueryVariables,
  RainfallDataByLocationInput,
} from '../../graphql/generated';
import { useTransformDataIntoRowData } from '../../hooks';
import { useGetRainfallDataByLocationsEndpointMutation } from '../../store/api';
import { IResource } from '../../store/bingMapApi/interfaces';

import { CheckerPage } from './CheckerPage';
import { InputType, SchemaType } from './schema';

export const QUOTING_BUFFER_DAYS = 10;

const SNAPSHOT_DATE = new Date('2022-08-01T00:00:00.000Z');
const BEFORE_SNAPSHOT_DATE_CUTOFF = subDays(SNAPSHOT_DATE, QUOTING_BUFFER_DAYS);

function mapValuesToRequest(values: InputType) {
  const location = values.location as IResource;
  const coordinates = location.point.coordinates;

  const city = location.address.locality || '';
  const country = location.address.countryRegion;

  return {
    city,
    country,
    endDate: values.dates[1].toISOString(),
    location: coordinates,
    startDate: values.dates[0].toISOString(),
  };
}

function getSnapshotDate(location: RainfallDataByLocationInput) {
  const isBeforeSnapshotDate = isBefore(
    new Date(location.endDate),
    BEFORE_SNAPSHOT_DATE_CUTOFF,
  );

  // If the last location is 10 days before the snapshot date:
  // then use the snapshot date
  // else use the last location's end date + 10 days
  return isBeforeSnapshotDate
    ? SNAPSHOT_DATE.toISOString()
    : addDays(new Date(location.endDate), 10).toISOString();
}

export function CheckerPageContainer() {
  const [mutate, { data: response, isLoading, error, reset: resetMutation }] =
    useGetRainfallDataByLocationsEndpointMutation();

  const [submittedValues, setSubmittedValues] = useState<
    SchemaType | undefined
  >(undefined);
  const [customError, setCustomError] = useState<string | undefined>();

  const rainThreshold = submittedValues?.rainThreshold || 0;

  const {
    isPrepping,
    tableData,
    reset: resetPreppedData,
    summary,
  } = useTransformDataIntoRowData(
    response?.getRainfallDataByLocations,
    rainThreshold,
  );

  const onSubmitHandler = useCallback(
    (values: SchemaType) => {
      const inputs: GetRainfallDataByLocationsQueryVariables['input'] =
        values.inputs
          .sort((a, b) => a.dates[1].getTime() - b.dates[1].getTime())
          .map(mapValuesToRequest);

      const lastLocation = inputs[inputs.length - 1];
      const asOfDate = getSnapshotDate(lastLocation);

      resetPreppedData();
      setSubmittedValues(values);
      mutate({ input: inputs, asOf: asOfDate }).then((results) => {
        if (
          'data' in results &&
          !results?.data?.getRainfallDataByLocations.length
        ) {
          setCustomError('No data found');
        }
      });
    },
    [mutate, resetPreppedData],
  );

  const resetHandler = useCallback(() => {
    resetMutation();
    setSubmittedValues(undefined);
    setCustomError(undefined);
  }, [resetMutation]);

  return (
    <Fragment>
      <CheckerPage
        data={tableData}
        isLoading={isLoading || isPrepping}
        onSubmit={onSubmitHandler}
        rainThreshold={rainThreshold}
        summary={summary}
      />
      <ErrorModal
        open={Boolean(error) || Boolean(customError)}
        onClose={resetHandler}
      >
        {customError}
      </ErrorModal>
    </Fragment>
  );
}
