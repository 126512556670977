import { z } from 'zod';

const findDatesThatAreNotAfterLastDate = (
  value: {
    dates: Date[];
    location: any;
  }[],
) => {
  return value.findIndex((item, index) => {
    const prevItem = value[index - 1];

    if (prevItem) {
      return item.dates[0] < prevItem.dates[1];
    }

    return false;
  });
};

export const schema = z.object({
  rainThreshold: z.number().min(0).max(50),
  inputs: z
    .array(
      z.object({
        location: z.object(
          {
            address: z.any(),
            name: z.string(),
            point: z.object({
              type: z.string(),
              coordinates: z.array(z.number()),
            }),
          },
          {
            required_error: 'Location is required',
            invalid_type_error: 'Location is required',
          },
        ),
        dates: z.array(z.date(), z.date()),
      }),
    )
    .refine(
      (values) => {
        return findDatesThatAreNotAfterLastDate(values) === -1;
      },
      (values) => {
        const index = findDatesThatAreNotAfterLastDate(values);

        return {
          message:
            'Start date cannot be on the same day or before the previous end date',
          path: [`[${index}].dates`],
        };
      },
    ),
});

export type SchemaType = z.infer<typeof schema>;
export type InputType = z.infer<typeof schema>['inputs'][0];
