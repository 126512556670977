import { styled, TableCell, tableCellClasses, TableRow } from '@mui/material';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: 0,
    color: theme.palette.grey[70],
    fontSize: '0.8125rem',
    lineHeight: 1,
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  [`&.${tableCellClasses.body}`]: {
    border: 0,
    color: theme.palette.grey[100],
    fontSize: 14,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  [`&.${tableCellClasses.body} span`]: {
    color: theme.palette.grey[80],
  },
  [`&.${tableCellClasses.body}:nth-of-type(1)`]: {
    color: theme.palette.grey[80],
  },
  [`&.${tableCellClasses.body}:nth-of-type(4)`]: {
    color: theme.palette.grey[80],
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.grey[10],
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
