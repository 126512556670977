import { subDays } from 'date-fns';
import { useMemo } from 'react';

import { QUOTING_BUFFER_DAYS } from '../../pages/CheckerPage';
import { SchemaType } from '../../pages/CheckerPage/schema';
import { useMetaERA5PrecipHourlyQuery } from '../../store/dclimateApi';

import { InputForm } from './InputForm';

interface IProps {
  isDisabled?: boolean;
  onSubmit: (value: SchemaType) => void;
}

export function InputFormContainer({ isDisabled, onSubmit }: IProps) {
  const { data, isLoading: dataIsLoading } =
    useMetaERA5PrecipHourlyQuery(undefined);

  const maxDate = useMemo(() => {
    if (!data) {
      return undefined;
    }

    const tenDaysAgo = subDays(new Date(), QUOTING_BUFFER_DAYS);
    const latestValidDate = new Date(data?.properties?.end_datetime);

    // Whichever is earlier
    return tenDaysAgo < latestValidDate ? tenDaysAgo : latestValidDate;
  }, [data]);

  const disabled = isDisabled || dataIsLoading;

  return (
    <InputForm isDisabled={disabled} maxDate={maxDate} onSubmit={onSubmit} />
  );
}
