import { Box, Button, Grid, InputAdornment } from '@mui/material';
import { Control } from 'react-hook-form';

import { SchemaType } from '../../pages/CheckerPage/schema';
import { ControllerTextField } from '../Inputs';

interface IProps {
  control: Control<SchemaType>;
  disabled?: boolean;
}

export function RightSideInputPanel({ control, disabled }: IProps) {
  return (
    <Box
      sx={{
        height: '100%',
      }}
    >
      <Grid container spacing={2}>
        {/* Row 1 */}
        <Grid item fontSize="0.8125rem" color="grey.80">
          DAILY RAIN THRESHOLD
        </Grid>
        {/* Row 2 */}
        <Grid item>
          <ControllerTextField
            disabled
            control={control}
            name="rainThreshold"
            type="number"
            inputProps={{
              step: 0.1,
              min: 0,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">mm</InputAdornment>,
            }}
          />
        </Grid>
        {/* Row 3 */}
        <Grid item width="100%">
          <Button
            color="secondary"
            disabled={disabled}
            fullWidth
            size="large"
            sx={{ mt: 1 }}
            type="submit"
            variant="contained"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
