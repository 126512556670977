import { Box, Grid } from '@mui/material';

export function RowHeaders() {
  return (
    <Grid container item spacing={2} width="100%" fontSize="0.8125rem">
      <Grid item color="grey.80" flexGrow={1}>
        LOCATION
      </Grid>
      <Grid item color="grey.80" xs={3}>
        FROM
      </Grid>
      <Grid item color="grey.80" xs={3}>
        TO
      </Grid>
      <Box pt={2} pl={1} mr={-1} width={32}></Box>
    </Grid>
  );
}
