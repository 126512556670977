import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { format } from 'date-fns';

interface IProps {
  maxDate?: Date;
}

export function DataValidInfo({ maxDate }: IProps) {
  return (
    <Grid container item spacing={2} width="100%">
      <Grid item xs={12}>
        <Box alignItems="center" display="block" flexDirection="row">
          <Typography component="span" color="grey.80">
            Data is valid as of{' '}
          </Typography>
          {maxDate ? (
            <Typography component="span">
              <strong>{format(maxDate, 'MMMM d, yyyy')}</strong>
            </Typography>
          ) : (
            <Skeleton
              sx={{
                bgcolor: 'grey.60',
                display: 'inline-block',
                verticalAlign: 'middle',
                borderRadius: '6px',
              }}
              height={32}
              width={80}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
