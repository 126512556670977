import { useCallback, useEffect, useState } from 'react';

import { IRowData, ISummary } from '../components/Results/interfaces';
import { GetRainfallDataByLocationsQuery } from '../graphql/generated';
import { getMissingCityCountryFromLatLng } from '../helpers/reverseGeocoder';
import {
  aggergateRowsIntoSummary,
  transformResponseTableRows,
} from '../helpers/transformers';
import { useLazyReverseGeocoderQuery } from '../store/bingMapApi';

export function useTransformDataIntoRowData(
  data?: GetRainfallDataByLocationsQuery['getRainfallDataByLocations'],
  threshold?: number,
) {
  const [isPrepping, setIsPrepping] = useState(false);
  const [tableData, setTableData] = useState<IRowData[] | undefined>();
  const [summary, setSummary] = useState<ISummary | undefined>();
  const [query] = useLazyReverseGeocoderQuery();

  useEffect(() => {
    async function transformData(
      data: GetRainfallDataByLocationsQuery['getRainfallDataByLocations'],
      threshold: number,
    ) {
      const massagedData = transformResponseTableRows(data, threshold);
      const summary = aggergateRowsIntoSummary(massagedData);

      for (const index in massagedData) {
        massagedData[index] = await getMissingCityCountryFromLatLng(
          massagedData[index],
          query,
        );
      }

      return { tableData: massagedData, summary };
    }

    if (data && threshold) {
      setIsPrepping(true);

      transformData(data, threshold)
        .then((result) => {
          setIsPrepping(false);
          setTableData(result.tableData);
          setSummary(result.summary);
        })
        .catch((error) => {
          console.error(error);

          setTableData(undefined);
          setSummary(undefined);
        })
        .finally(() => {
          setIsPrepping(false);
        });
    }
  }, [data, query, threshold]);

  const reset = useCallback(() => {
    setIsPrepping(false);
    setTableData(undefined);
    setSummary(undefined);
  }, []);

  return { isPrepping, tableData, reset, summary };
}
