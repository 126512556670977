import DateRangeIcon from '@mui/icons-material/DateRange';
import { TextField } from '@mui/material';
import {
  DateRange,
  DateRangePicker,
  DateRangePickerProps,
} from '@mui/x-date-pickers-pro';
import { Control, Controller, Path } from 'react-hook-form';

import { SchemaType } from '../../pages/CheckerPage/schema';

import { renderWeekPickerDay } from './StyledDatePickerComps';

interface IProps {
  control: Control<SchemaType>;
  dateRangeProps?: Partial<DateRangePickerProps<Date, Date>>;
  name: Path<SchemaType>;
  type: 'start' | 'end';
}

export function DatePickerController({
  control,
  dateRangeProps,
  name,
  type,
}: IProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <DateRangePicker
          {...dateRangeProps}
          ignoreInvalidInputs-
          onChange={field.onChange}
          renderDay={renderWeekPickerDay}
          value={field.value as DateRange<Date>}
          inputFormat="dd/MM/yyyy"
          renderInput={(startProps, endProps) => (
            <TextField
              {...(type === 'start' ? startProps : endProps)}
              label={null}
              error={Boolean(error)}
              helperText={type === 'start' ? error?.message : undefined}
              InputProps={{
                endAdornment: (
                  <DateRangeIcon
                    sx={{ opacity: 0.5 }}
                    onClick={(event) => {
                      if (dateRangeProps?.disabled) {
                        return;
                      }

                      const currentProps =
                        type === 'start' ? startProps : endProps;
                      currentProps.inputProps?.onClick?.(event as any);
                    }}
                  />
                ),
              }}
            />
          )}
        />
      )}
    />
  );
}
