import { Box, Link } from '@mui/material';

import { ArbolLogoSVG } from '../SVGs';

const FOOTER_HEIGHT = '118px';

export function Footer() {
  return (
    <Box
      bottom={0}
      height={FOOTER_HEIGHT}
      position="absolute"
      py={4}
      textAlign="center"
      width="100%"
    >
      <Link href="https://www.arbolmarket.com/">
        <ArbolLogoSVG sx={{ height: '100%', width: 'auto' }} />
      </Link>
    </Box>
  );
}
