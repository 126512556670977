import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Grid, IconButton } from '@mui/material';
import { addDays, subYears } from 'date-fns';
import { useMemo } from 'react';
import { Control } from 'react-hook-form';

import { SchemaType } from '../../pages/CheckerPage/schema';
import { LocationAutocomplete } from '../LocationAutocomplete';

import { DatePickerController } from './DatePickerController';

interface IProps {
  control: Control<SchemaType>;
  disabled?: boolean;
  index: number;
  maxDate?: Date;
  onRemoveRow?: (index: number) => void;
}

export function InputRow({
  control,
  disabled,
  index,
  maxDate,
  onRemoveRow,
}: IProps) {
  const minDate = useMemo(() => addDays(subYears(new Date(), 1), 1), []);

  return (
    <Grid
      alignItems="flex-start"
      container
      item
      spacing={2}
      width="100%"
      sx={{
        '&:hover .delete-icon': {
          opacity: onRemoveRow ? 1 : 0,
        },
      }}
    >
      <Grid item flexGrow={1}>
        <LocationAutocomplete
          control={control}
          disabled={disabled}
          name={`inputs.${index}.location`}
          placeholder="By city, country or lat & long"
        />
      </Grid>

      <Grid item xs={3}>
        <DatePickerController
          control={control}
          name={`inputs.${index}.dates`}
          dateRangeProps={{
            disabled,
            maxDate,
            minDate,
          }}
          type="start"
        />
      </Grid>

      <Grid item xs={3} display="flex" alignItems="center">
        <DatePickerController
          control={control}
          name={`inputs.${index}.dates`}
          dateRangeProps={{
            disabled,
            maxDate,
            minDate,
          }}
          type="end"
        />
      </Grid>
      <IconButton
        className="delete-icon"
        disabled={onRemoveRow === undefined}
        disableRipple
        disableTouchRipple
        disableFocusRipple
        onClick={() => {
          if (onRemoveRow) {
            onRemoveRow(index);
          }
        }}
        sx={{
          alignItems: 'center',
          display: 'flex',
          mr: -1,
          opacity: 0,
          pb: 0,
          pl: 1,
          pt: 4,
          transition: 'opacity 0.15s ease-in-out',
          width: 32,
        }}
      >
        <DeleteOutlineOutlinedIcon color="error" />
      </IconButton>
    </Grid>
  );
}
