import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Stack, Typography, Button, Paper, Link } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';

import { ControllerTextField } from '../../components/Inputs';

import {
  IForgotPasswordPageSchema,
  getForgotPasswordPageSchema,
} from './ForgotPassword.schema';

interface IProps {
  isLoading?: boolean;
  errorMessage?: string;
  onSubmit: SubmitHandler<IForgotPasswordPageSchema>;
}

export function ForgotPasswordPage({
  errorMessage,
  isLoading,
  onSubmit,
}: IProps) {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(getForgotPasswordPageSchema()),
  });

  return (
    <Paper elevation={0} sx={{ maxWidth: 500, px: 4, py: 4, width: '100%' }}>
      <Typography textAlign="center" variant="h2">
        Forgot Password?
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        marginX="auto"
        marginTop="20px"
        minWidth={300}
        maxWidth={400}
        width="100%"
      >
        <Stack spacing={2} marginBottom={4}>
          <ControllerTextField
            autoComplete="username"
            name="email"
            control={control}
            label={'Email'}
            fullWidth
          />
          {errorMessage && (
            <Typography marginBottom={1} color="error">
              {errorMessage}
            </Typography>
          )}
          <Box>
            <Link
              color="primary"
              component={RouterLink}
              sx={{ textDecoration: 'none' }}
              to="/"
            >
              I remember my password
            </Link>
          </Box>
        </Stack>
        <Button
          color="secondary"
          disabled={isLoading}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Send Reset
        </Button>
      </Box>
    </Paper>
  );
}
