import { LicenseInfo } from '@mui/x-license-pro';
import ReactDOM from 'react-dom';

import { StrictModeApp } from './components/App';
import { config } from './config/config';
import './index.css';

LicenseInfo.setLicenseKey(config.mui.licenseKey);

ReactDOM.render(<StrictModeApp />, document.getElementById('root'));
