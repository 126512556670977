import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { StyledTableCell, StyledTableRow } from './StyledTableComps';
import { IRowData } from './interfaces';

interface IProps {
  rows?: Array<IRowData>;
}

export function ResultTable({ rows = [] }: IProps) {
  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHead sx={{ border: 0 }}>
            <TableRow>
              <StyledTableCell>DAY</StyledTableCell>
              <StyledTableCell>CITY</StyledTableCell>
              <StyledTableCell>COUNTRY</StyledTableCell>
              <StyledTableCell>LAT/LONG</StyledTableCell>
              <StyledTableCell>
                <Box>
                  DATE <ArrowDownwardIcon sx={{ height: 12, width: 12 }} />
                </Box>
              </StyledTableCell>
              <StyledTableCell>RAINFALL</StyledTableCell>
              <StyledTableCell>ABOVE THRESHOLD</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>#{index + 1}</StyledTableCell>
                <StyledTableCell>{row.city}</StyledTableCell>
                <StyledTableCell>{row.country}</StyledTableCell>
                <StyledTableCell>{row.latLng}</StyledTableCell>
                <StyledTableCell>{row.date}</StyledTableCell>
                <StyledTableCell>
                  {Number(row.rainfall).toFixed(1)} <span>mm</span>
                </StyledTableCell>
                <StyledTableCell>
                  {row.aboveThreshold ? 'Yes' : 'No'}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
