import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, Grid, Typography } from '@mui/material';

import { InfoBox } from '../InfoBox/InfoBox';

interface IProps {
  isLoading?: boolean;
  qualified?: boolean;
  threshold?: number;
  thresholdDays?: number;
  thresholdPercentage?: number;
  onClick?: () => void;
}

export function ResultHeader({
  isLoading,
  onClick,
  qualified,
  thresholdDays = 0,
  thresholdPercentage = 0,
  threshold = 0,
}: IProps) {
  const formattedThresholdDays = `${thresholdDays} Days`;
  const formattedThresholdPercentage = `${(thresholdPercentage * 100).toFixed(
    1,
  )}%`;
  const formattedQualified = qualified ? 'Yes' : 'No';

  return (
    <Box mb={3}>
      <Typography color="grey.50" fontSize="2.5rem" mb={1} variant="h2">
        Results
      </Typography>
      <Grid container width="100%">
        <Grid item>
          <InfoBox
            isLoading={isLoading}
            title={`Days > ${threshold}`}
            value={formattedThresholdDays}
          />
        </Grid>
        <Grid item>
          <InfoBox
            isLoading={isLoading}
            title={`% of days > ${threshold}`}
            value={formattedThresholdPercentage}
          />
        </Grid>
        <Grid item>
          <InfoBox
            isLoading={isLoading}
            title="Qualify for insurance"
            value={formattedQualified}
          />
        </Grid>
        <Grid item alignSelf="flex-end" flexGrow={1} textAlign="right">
          <Button
            onClick={onClick}
            sx={{ color: 'success.main', paddingBottom: 0 }}
            variant="text"
          >
            ERA5 Provided Data{' '}
            <InfoOutlinedIcon sx={{ width: 16, marginLeft: '2px' }} />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
