import { format } from 'date-fns';

import { IRowData, ISummary } from '../components/Results/interfaces';
import { GetRainfallDataByLocationsQuery } from '../graphql/generated';

import { formatLatLng } from './format';

function formatHypenedDate(dateString: string) {
  const reformatedDateString = dateString.replace(/-/g, '/');

  return format(new Date(reformatedDateString), 'd/M/yyyy');
}

export function transformResponseTableRows(
  data: GetRainfallDataByLocationsQuery['getRainfallDataByLocations'],
  rainThreshold: number,
): IRowData[] {
  if (!data) {
    return [];
  }

  return data.map(
    (item): IRowData => ({
      aboveThreshold: item.rainfall > rainThreshold,
      city: item.city,
      coordinates: item.location as [number, number],
      country: item.country,
      date: formatHypenedDate(item.date),
      latLng: formatLatLng(item.location as [number, number]),
      rainfall: item.rainfall,
    }),
  );
}

export function aggergateRowsIntoSummary(rows: IRowData[]): ISummary {
  const daysAboveThreshold = rows.filter((row) => row.aboveThreshold).length;
  const percentageAboveThreshold = daysAboveThreshold / rows.length;

  // "Number of days with a 24 hour rainfall of >6.4mm exceeds 50% of the total number of days in a trip"
  const qualified = percentageAboveThreshold > 0.5;

  return {
    daysAboveThreshold,
    percentageAboveThreshold,
    qualified,
  };
}
