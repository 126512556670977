import CancelIcon from '@mui/icons-material/Cancel';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Typography,
} from '@mui/material';

import { HorizontalRuler } from '../HorizontalRuler';

interface IProps {
  href?: string;
  linkText?: string;
}

function ResourceLink({ href, linkText }: IProps) {
  return (
    <Link
      href={href}
      target="_blank"
      rel="noopener"
      underline="none"
      sx={{
        color: '#688A3F',
        fontSize: '16px',
        fontWeight: 600,
      }}
    >
      {linkText} <OpenInNewIcon sx={{ width: 18, verticalAlign: 'middle' }} />
    </Link>
  );
}

function ModalFooter() {
  return (
    <Grid container>
      <Grid item xs={12} my={2}>
        <Typography
          sx={{
            color: 'black',
            fontSize: '16px',
            fontWeight: 600,
          }}
        >
          Resources
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2} pl={2}>
        <ResourceLink
          href="https://www.ecmwf.int/en/forecasts/datasets/reanalysis-datasets/era5"
          linkText="ERA5 Official Site"
        />
      </Grid>
      <Grid item xs={12} mb={1} pl={2}>
        <ResourceLink
          href="https://cds.climate.copernicus.eu/cdsapp#!/dataset/reanalysis-era5-single-levels?tab=overview"
          linkText="ERA5 Hourly Data"
        />
      </Grid>
    </Grid>
  );
}

export function ERA5Modal({ open, onClose }: DialogProps) {
  return (
    <Dialog
      PaperProps={{
        sx: {
          padding: 3,
        },
      }}
      onClose={onClose}
      open={open}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          disableFocusRipple
          disableRipple
          disableTouchRipple
          onClick={() => onClose?.({}, 'backdropClick')}
          sx={{
            position: 'absolute',
            top: '22px',
            right: '25px',
          }}
        >
          <CancelIcon
            sx={{ color: '#3F5F69', height: '28px', width: '28px' }}
          />
        </IconButton>
        <Typography fontSize="1.75rem" fontWeight={600}>
          ERA5 Data
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography mb={2} color="grey.80" variant="body1">
          ERA5 is the fifth generation ECMWF reanalysis for the global climate
          and weather for the past 4 to 7 decades. Currently data is available
          from 1950, with Climate Data Store entries for 1950-1978 (preliminary
          back extension) and from 1959 onwards (final release plus timely
          updates, this page). ERA5 replaces the ERA-Interim reanalysis.
        </Typography>
        <Typography mb={2} color="grey.80" variant="body1">
          ERA5 provides hourly estimates of a large number of atmospheric, land
          and oceanic climate variables. The data cover the Earth on a 30km grid
          and resolve the atmosphere using 137 levels from the surface up to a
          height of 80km. ERA5 includes information about uncertainties for all
          variables at reduced spatial and temporal resolutions.
        </Typography>
        <Typography color="grey.80" variant="body1" mb={2}>
          Quality-assured monthly updates of ERA5 (1979 to present) are
          published within 3 months of real time. Preliminary daily updates of
          the dataset are available to users within 5 days of real time.
        </Typography>
        <HorizontalRuler />
        <ModalFooter />
      </DialogContent>
    </Dialog>
  );
}
