import {
  GetRainfallDataByLocationsDocument,
  GetRainfallDataByLocationsQueryVariables,
} from '../../../graphql/generated';

export const getRainfallDataByLocationsEndpoint = {
  query: (variables: GetRainfallDataByLocationsQueryVariables) => ({
    document: GetRainfallDataByLocationsDocument,
    variables,
  }),
};
