import { CssBaseline, Theme, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { EnhancedStore } from '@reduxjs/toolkit';
import { StrictMode, Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { theme as defaultTheme } from '../../config';
import { RootState, store as defaultStore } from '../../store/configureStore';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { Initializer } from '../Initializer';
import { Router } from '../Router';

interface IProps {
  store?: EnhancedStore<RootState>;
  theme?: Theme;
}

export function App({ store = defaultStore, theme = defaultTheme }: IProps) {
  return (
    <Suspense fallback="Loading">
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        localeText={{ start: 'Check-in', end: 'Check-out' }}
      >
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Initializer>
              <Header />
              <BrowserRouter>
                <Router />
              </BrowserRouter>
              <Footer />
            </Initializer>
          </ThemeProvider>
        </Provider>
      </LocalizationProvider>
    </Suspense>
  );
}

export const StrictModeApp = () => (
  <StrictMode>
    <App />
  </StrictMode>
);
