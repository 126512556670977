import { useLocation, Navigate, Outlet } from 'react-router-dom';

import { redirects } from '../../config/redirects';
import { useAuth } from '../../hooks';

export function UnprotectedRouteCheck() {
  const { isLoggedIn, isConfirmed } = useAuth();
  const location = useLocation();

  if (isLoggedIn && isConfirmed) {
    return (
      <Navigate
        to={redirects.protectedRoute}
        state={{ from: location }}
        replace
      />
    );
  }

  return <Outlet />;
}
