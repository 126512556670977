import { IRowData } from '../components/Results/interfaces';
import { useLazyReverseGeocoderQuery } from '../store/bingMapApi';
import { IBingLocationResponse } from '../store/bingMapApi/interfaces';

const reverseGeocoderCache = new Map<string, IBingLocationResponse>();

type ReverseGeocoderQuery = ReturnType<typeof useLazyReverseGeocoderQuery>[0];

export async function reverseGeocoder(
  latLng: string,
  query: ReverseGeocoderQuery,
) {
  if (reverseGeocoderCache.has(latLng)) {
    return reverseGeocoderCache.get(latLng);
  }

  const response = await query(latLng);

  if ('data' in response && response.data) {
    reverseGeocoderCache.set(latLng, response?.data);
  }

  return response?.data;
}

function getCityName(location?: IBingLocationResponse) {
  if (
    location?.resourceSets?.[0]?.resources?.[0]?.address?.countryRegion ===
    'United States'
  ) {
    return location?.resourceSets?.[0]?.resources?.[0]?.address?.locality;
  }

  return (
    location?.resourceSets?.[0]?.resources?.[0]?.address?.adminDistrict ||
    location?.resourceSets?.[0]?.resources?.[0]?.address?.locality
  );
}

export async function getMissingCityCountryFromLatLng(
  item: IRowData,
  query: ReverseGeocoderQuery,
) {
  if (item.city && item.country) {
    return item;
  }

  const { coordinates } = item;

  const results = await reverseGeocoder(String(coordinates), query);

  const city = getCityName(results);

  item.city = city || '';
  item.country =
    results?.resourceSets?.[0]?.resources?.[0]?.address?.countryRegion || '';

  return item;
}
