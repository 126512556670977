import { api } from './api';

export const {
  useAuthorizeMutation,
  useCurrentUserQuery,
  useForgotPasswordMutation,
  useLazyCurrentUserQuery,
  useLoginMutation,
  useResetPasswordMutation,
  useGetRainfallDataByLocationsEndpointMutation,
} = api;
